import * as api from '../api';
import { PURGE } from 'redux-persist';
import { API_ERROR } from './error';
const GET_FLEET_COUPONS = 'fleetCoupons/GET_COUPONS';
const GET_CANCELLED_FLEET_COUPONS = 'fleetCoupons/GET_CANCELLED_COUPONS';
const UPDATE_FLEET_COUPONS = 'fleetCoupons/UPDATE_COUPONS';
const initialState = {
  active: [],
  cancelled: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_FLEET_COUPONS:
      return  action.data;
    case GET_CANCELLED_FLEET_COUPONS:
      return Object.assign({}, state, { cancelled: action.data });
    case UPDATE_FLEET_COUPONS:
      return state;
    case PURGE:
      return initialState;
    default:
      return state;
  }
}

export const getCoupons = (jwt, fleetId) => {
  return (dispatch) => {
    return api
      .getCoupons(jwt, fleetId)
      .then((Coupons) => {
        dispatch({ type: GET_FLEET_COUPONS, data: Coupons });
      });
  };
};

export const updateCoupon = (jwt, fleetId, CouponId, newParams) => {
  return (dispatch) => {
    return api.patchCoupon(jwt, fleetId, CouponId, newParams)
      .then((response) => {
        if (response.coupon) {
          dispatch({ type: UPDATE_FLEET_COUPONS, data: response.coupon });
        } else {
          dispatch({ type: API_ERROR, error: 'Coupon update failed' });
        }
      })
      .catch((error) => {
        dispatch({ type: API_ERROR, error: error.message });
      });
  };
};

export const addCoupon = (jwt, fleetId, CouponData) => {
  return (dispatch) => {
    return api
      .addNewCoupon(jwt, fleetId, CouponData)
      .then(({ success, error }) => {
        if (success) {
          return api.getCoupons(jwt, fleetId);
        } else {
          dispatch({ type: API_ERROR, error });
          return { error };
        }
      });
  };
};

export const deleteCoupon = (jwt, fleetId, couponId) => {
  return (dispatch) => {
    return api
      .deleteCoupon(jwt, fleetId, couponId)
      .then(({ success, error }) => {
        if (!success) {
          dispatch({ type: API_ERROR, error });
        }
      });
  };
};

export const toggleCouponActive = (token, fleetId, couponId, isActive) => {
  return (dispatch) => {
    return fetch(`/api/fleets/${fleetId}/coupons/${couponId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ is_active: isActive }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          return Promise.reject(data.error);
        }
        return data;
      });
  };
};


